import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { QuestionnaireItemInputType } from '../../helpers/constants';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextQuestion from '../Questionaire/TextQuestion';
import NumberQuestion from '../Questionaire/NumberQuestion';
import OneChoiceQuestion from '../Questionaire/OneChoiceQuestion';
import MultipleChoiceQuestion from '../Questionaire/MultipleChoiceQuestion';

const useStyles = makeStyles(theme => ({
  formContainer: {
    width: '100%',
    alignSelf: 'center',
    padding: 16,
    flex: 1,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column'
  },
  button: {
    color: 'white',
    padding: '10px 30px',
  },
  greenButton: {
    background: '#42b742',
    color: 'white',
    padding: '10px 30px',
    '&:hover': {
      background: '#42b742',
    }
  }
}));

const MAP_COMPONENT = {
  [QuestionnaireItemInputType.TEXT]: TextQuestion,
  [QuestionnaireItemInputType.NUMBER]: NumberQuestion,
  [QuestionnaireItemInputType.ONE_CHOICE]: OneChoiceQuestion,
  [QuestionnaireItemInputType.MULTIPLE_CHOICE]: MultipleChoiceQuestion,
};

const MAP_DEFAULT_VALUE = {
  [QuestionnaireItemInputType.TEXT]: '',
  [QuestionnaireItemInputType.NUMBER]: '',
  [QuestionnaireItemInputType.ONE_CHOICE]: '',
  [QuestionnaireItemInputType.MULTIPLE_CHOICE]: [],
};

const Questionaire = ({ questions, onSave }) => {
  const formRef = useRef(null);
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const questionLength = questions.length;
  const [data, setData] = useState(Array(questionLength));

  const question = questions[step];
  const QuestionComponent = MAP_COMPONENT[question.input_type];

  function onBackStep() {
    setStep(Math.max(0, step - 1));
  };

  function onNextStep() {
    setLoading(false);
    if (formRef.current) {
      if (!formRef.current.validate()) return;
      setStep(Math.min(questionLength - 1, step + 1))
    }
  }

  function tryToNext() {
    if (step < questionLength - 1) {
      setLoading(false);
      setStep(Math.min(questionLength - 1, step + 1))
    } else {
      onSubmit();
    }
  }

  function onSubmit() {
    if (formRef.current) {
      if (!formRef.current.validate()) return;
      setLoading(true);
      onSave(cleanData(data));
    }
  }

  function cleanData(data) {
    return data.map((answer, index) => {
      return answer || MAP_DEFAULT_VALUE[questions[index].input_type]
    })
  }

  function setValue(value) {
    data[step] = value;
    const newData = [...data];
    setData(newData);
  }

  return (
    <Grid item className={classes.formContainer} xs={12} sm={6}>
      <Box width="100%">
        <QuestionComponent ref={formRef} tryToNext={tryToNext} question={question} data={data[step]} setValue={setValue} />
      </Box>
      <Box justifyContent="space-between" display="flex" alignItems="center" width="100%">
        <Button
          style={{ visibility: step > 0 ? 'visible' : 'hidden' }}
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={onBackStep}
        >
          ย้อนกลับ
        </Button>
        {step === questionLength - 1 ? (
          <Button
            style={{ visibility: step === questionLength - 1 ? 'visible' : 'hidden' }}
            className={classes.greenButton}
            onClick={onSubmit}
            disabled={loading}
          >
            รับผลประเมิน
          </Button>
        ) : (
          <Button
            style={{ visibility: step < questionLength - 1 ? 'visible' : 'hidden' }}
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={onNextStep}
          >
            ถัดไป
          </Button>
        )}
      </Box>
    </Grid>
  )
}

export default Questionaire;
