import React, { useState, useEffect } from 'react'
import { createForm } from 'rc-form';
import { useSelector } from 'react-redux';
import { RelationType, GenderType } from '../../helpers/constants';
import { makeStyles } from '@material-ui/core/styles';
import * as Api from '../../apis';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Table from '../Table';
import Cell from '../Table/cell';
import BirthDateInput from '../BirthDateInput';

const useStyles = makeStyles(theme => ({
  red: {
    color: 'red'
  },
  tableSpace: {
    borderSpacing: '0px 20px',
    borderCollapse: 'separate'
  }
}));

const NewRelationDialog = ({ form, open, onSaved, onClose, forceSelf = false }) => {
  const { validateFields, getFieldDecorator, getFieldError, setFieldsValue } = form;
  const classes = useStyles();
  const [provinces, setProvinces] = useState('');
  const user = useSelector(state => state.session.user.id);

  useEffect(() => {
    setFieldsValue({});
  }, [open]);

  useEffect(() => {
    Api.getProvinces().then(data => {
      setProvinces(data);
    })
  }, []);

  const submit = () => {
    validateFields((error, value) => {
      if (!error) {
        Api.addUserRelation({...value, user}).then(result => {
          onSaved(result);
        });
      }
    });
  }

  return (
    <Dialog open={open} fullWidth={true} aria-labelledby="form-dialog-title">
      {
        forceSelf && (
          <DialogTitle id="form-dialog-title">
            กรุณากรอกข้อมูลตัวเอง
          </DialogTitle>
        )
      }
      <DialogContent>
        <Table classes={{ root: classes.tableSpace }}>
          <TableBody>
            <TableRow>
              <Cell style={{ minWidth: '100px' }}><span>คุณประเมินให้ใคร</span></Cell>
              <Cell>
                {
                  getFieldDecorator('relation', {
                    rules: [{ required: true }],
                    initialValue: RelationType.SELF,
                  })(
                    <Select
                      disabled={forceSelf}
                      fullWidth
                      native
                      error={getFieldError('relation')}
                    >
                      <option value={RelationType.SELF}>ประเมิน - ตัวเอง</option>
                      <option value={RelationType.CHILD}>ประเมิน - ให่้ลูก</option>
                      <option value={RelationType.PARENT}>ประเมิน - ให้พ่อแม่</option>
                      <option value={RelationType.OTHER}>อื่นๆ</option>
                    </Select>
                  )
                }
              </Cell>
            </TableRow>
            <TableRow>
              <Cell style={{ minWidth: '100px' }}><span>ชื่อ - นามสกุล</span></Cell>
              <Cell>
                {
                  getFieldDecorator('fullname', {
                    rules: [{ required: true }],
                  })(
                    <Input
                      fullWidth
                      error={getFieldError('fullname')}
                    />
                  )
                }
              </Cell>
            </TableRow>
            <TableRow>
              <Cell style={{ minWidth: '100px' }}><span className={classes.titleInput}>วัน/เดือน/ปีเกิด</span></Cell>
              <Cell>
                {
                  getFieldDecorator('birthday', {
                    rules: [{ required: true }],
                    getValueFromEvent: function (e) { return e }
                  })(
                    <BirthDateInput
                      native
                      error={getFieldError('birthday')}
                    />
                  )
                }
              </Cell>
            </TableRow>
            <TableRow>
              <Cell style={{ minWidth: '100px' }}><span>เพศ</span></Cell>
              <Cell>
                {
                  getFieldDecorator('gender', {
                    rules: [{ required: true }],
                  })(
                    <Select
                      fullWidth
                      native
                      error={getFieldError('gender')}
                    >
                      <option value="">เลือก</option>
                      <option value={GenderType.MALE}>ชาย</option>
                      <option value={GenderType.FEMALE}>หญิง</option>
                    </Select>
                  )
                }
              </Cell>
            </TableRow>
            <TableRow>
              <Cell style={{ minWidth: '100px' }}><span>จังหวัด</span></Cell>
              <Cell>

                {
                  getFieldDecorator('province', {
                    rules: [{ required: true }],
                    getValueFromEvent: function (e, v) { return v.id }
                  })(
                    <Autocomplete
                      error={getFieldError('province')}
                      options={provinces}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        />
                      )}
                    />
                  )
                }
              </Cell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className={classes.red}>
          ยกเลิก
        </Button>
        <Button onClick={submit} color="primary">
          บันทึก
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default createForm()(NewRelationDialog);
