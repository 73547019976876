import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { getUrlParams } from '../helpers/location';
import * as Api from '../apis';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import useActiveSession from '../hooks/useActiveSession';
import withAuth from '../hooks/withAuth';
import Questionaire from '../components/Questionaire';
import Consent from '../components/Consent';
import Layout from '../components/Layout';
import LoaderView from '../components/LoaderView';

const Questionnaire = ({ location }) => {
  const [questionaire, setQuestionaire] = useState(null);
  const [isStart, setIsStart] = useState(false);
  const [userRelation, setUserRelation] = useState(false);
  const query = getUrlParams(location.search);
  const questionnaireId = query.questionnaireId;
  const { cardId, partnerId } = useSelector(state => state.partners);

  useActiveSession(() => {
    Api.getQuestionnaireById(questionnaireId).then(result => {
      window.logEvent('select_content', {
        content_type: 'questionnaire',
        item_id: questionnaireId + '',
        item_name: result.topic,
      });
      setQuestionaire(result);
    });
  });

  function handleOnSave(data) {
    Api.postSubmitQuestionnaire(questionnaireId, data, userRelation, cardId, partnerId, !!partnerId).then(
      data => {
        Api.getResult(questionnaireId).then(data => {
          navigate(`/questionnaire_success?questionnaireId=${questionnaireId}`);
        });
      },
    );
  }

  function handleOnConfirm(_userRelation) {
    setUserRelation(_userRelation);
    setIsStart(true);
  }

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>แบบประเมิน - MorOnline</title>
      </Helmet>
      <LoaderView loading={!questionaire}>
        {isStart ? (
          <Questionaire questions={questionaire.items} onSave={handleOnSave} />
        ) : (
          <Consent questionaire={questionaire} onConfirm={handleOnConfirm} />
        )}
      </LoaderView>
    </Layout>
  );
};

export default withAuth(Questionnaire);
