import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moronlineLogo from '../../../static/img/LogoMorNoLetter.png';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  logo: {
    width: 200,
    height: 200
  },
  container: {
    paddingTop: '30px'
  }
}));

const Logo = () => {
  const classes = useStyles();

  return (
    <Grid container direction='column' alignItems='center' className={classes.container}>
      <Grid item>
        <img className={classes.logo} src={moronlineLogo} />
      </Grid>
    </Grid>
  );
};

export default Logo;
