import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import PostAddIcon from '@material-ui/icons/PostAdd';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DeleteIcon from '@material-ui/icons/Delete';
import * as Api from '../../apis';
import NewRelationDialog from '../NewRelationDialog';
import Logo from '../Logo'
import TermAndConditionDialog from '../TermAndConditionDialog'

const useStyles = makeStyles(theme => ({
  formContainer: {
    flex: 2
  },
  formContent: {
    margin: '0px 30px',
    padding: 10,
    textAlign: 'center'
  },
  button: {
    color: 'white',
    padding: '10px 30px',
  },
  greenButton: {
    background: '#42b742',
    color: 'white',
    padding: '10px 30px',
    '&:hover': {
      background: '#42b742',
    }
  },
  blueButton: {
    background: '#2990ff',
    color: 'white',
    padding: '10px 30px',
    '&:hover': {
      background: '#2990ff',
    }
  },
  redButton: {
    padding: '10px 15px',
    fontSize: '16px',
    background: 'red',
    color: 'white',
    '&:hover': {
      background: 'red',
    }
  },
  actionContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  radioLabel: {
    fontSize: 16
  },
  input: {
    background: 'ivory',
    margin: '5px 0px'
  },
  detailContainer: {
    paddingLeft: 30
  },
  helperText: {
    fontSize: 16
  },
  radioLabelRoot: {
    alignItems: 'end',
    padding: '9px 0px'
  },
  radioRoot: {
    padding: '0px 9px'
  },
  removeButton: {
    minWidth: '40px',
    height: '40px',
    padding: '5px',
    marginLeft: '15px'
  },
  textTerm: {
    color: 'gray',
    fontWeight: 'bold',
  },
  termLink: {
    color: 'teal',
    textDecoration: 'underline',
  }
}));

const Consent = ({ questionaire, onConfirm }) => {
  const classes = useStyles();
  const [userRelation, setUserRelation] = useState(null);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [relations, setRelations] = useState([]);
  const [termOpen, setTermOpen] = useState(false);
  const requireSelfRelation = [70, 71, 72, 73, 74, 1].includes(questionaire.id);

  useEffect(() => {
    fetchRelations();
  }, []);

  function fetchRelations() {
    Api.getUserRelations().then(data => {
      let _relations = [];
      // setRelations(data.results);
      // const firstRelation = data.results[0];
      // if (firstRelation) {
      //   setUserRelation(firstRelation.id);
      // }

      if (requireSelfRelation) {
        _relations = data.results.filter(relation => relation.relation === 'self');
      } else {
        _relations = data.results;
      }
      setRelations(_relations);
      const firstRelation = _relations[0];
      if (firstRelation) {
        setUserRelation(firstRelation.id);
      }
    })
  }

  function removeRelation(relationId) {
    Api.removeUserRelation(relationId).then(_ => {
      const newRelation = relations.filter(relation => relation.id !== relationId);
      setRelations(newRelation);
    })
  }

  function handleChoiceChange(event) {
    setUserRelation(parseInt(event.target.value));
  }

  function onSavedRelation(data) {
    setRelations([...relations, data]);
    setUserRelation(data.id);
    setOpen(false);
  }

  function handleConfirm() {
    if (!userRelation) {
      alert('กรุณาเลือกผู้ที่ได้รับการประเมิน');
      return;
    }
    if (!checked) {
      alert('กรุณายอมรับ Term & Condition');
      return;
    }
    onConfirm(userRelation);
  }

  function onChange() {
    setChecked(!checked)
  }

  function onOpenTerm(e) {
    e.preventDefault();
    setTermOpen(true);
  }

  return (
    <>
      <NewRelationDialog
        open={open}
        forceSelf={requireSelfRelation}
        onSaved={onSavedRelation}
        onClose={() => setOpen(false)}
      />
      <TermAndConditionDialog
        open={termOpen}
        handleClose={() => setTermOpen(false)}
      />
      <Logo />
      <Grid item className={classes.formContainer}>
        <Box className={classes.formContent}>
          <h2 align="center" style={{ marginTop: 0 }}>{questionaire.name}</h2>
          {
            !!relations.length && (
              <h3 align="center">กรุณาเลือกผู้ที่ได้รับการประเมิน</h3>
            )
          }
          <FormControl component="fieldset">
            <RadioGroup value={userRelation} onChange={handleChoiceChange}>
              {relations.map((relation) => (
                <Box justifyContent="space-between" display="flex" alignitem="start" key={relation.id}>
                  <FormControlLabel
                    classes={{ root: classes.radioLabelRoot }}
                    value={relation.id}
                    control={<Radio classes={{ root: classes.radioRoot }} />}
                    label={<span>{relation.fullname} ({relation.relation_name})</span>}
                  />
                  <Button
                    onClick={() => { removeRelation(relation.id) }}
                    className={`${classes.redButton} ${classes.removeButton}`}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
          <br />
          <br />
          <Button
            className={classes.blueButton}
            onClick={() => setOpen(true)}
          >
            <PostAddIcon /> เพิ่มผู้รับการประเมิน
          </Button>
        </Box>
      </Grid>
      {
        !!relations.length && (
          <Grid item className={classes.actionContainer}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={onChange}
                  />
                }
                label={<span className={classes.textTerm}>ยอมรับ <a onClick={onOpenTerm} className={classes.termLink}>เงื่อนไขการให้บริการ</a></span>}
              />
            </Box>
            <Box justifyContent="center" display="flex" alignItems="center" p={1}>
              <Button
                className={classes.greenButton}
                onClick={handleConfirm}
              >
                เริ่มทำแบบประเมิน
              </Button>
            </Box>
          </Grid>
        )
      }
    </>
  )
}

export default Consent
